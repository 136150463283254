import EventEmitter from 'eventemitter3';
import SIP from 'sip.js';

export default class SpeechCloud extends EventEmitter {

    constructor(options) {
        super();

        this.options = options;
        this.config = undefined;
        this.isRecognizing = false;
        this.initialize_sip_later = Boolean(this.options.initialize_sip_later); 
        this._api_methods = null;
        this._api_events = null;

        this.disable_audio_processing = Boolean(this.options.disable_audio_processing);

        if (!('uri' in this.options) && !this.options.uri) {
            throw 'Options does not contains SpeechCloud uri.';
        }

        if ('public_key' in this.options && !('private_key' in this.options)) {
            throw 'Options does contains public_key but does not contain private_key.';
        }
    }

    availableMethods() {
        return this._api_methods;
    }

    availableEvents() {
        return this._api_events;
    }

    init() {
        var uri = this.options.uri;

        // Get configuration from server and establish SIP and WS connection.
        const request = new XMLHttpRequest();
        request.open('GET', uri, true);

        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Parse received configuration.
                this.config = JSON.parse(request.responseText);

                this._uaConfig = {
                    uri: this.config.sip_username,       // SIP username
                    traceSip: true,
                    log: {
                        level: "debug"
                    },
                    transportOptions: {
                        wsServers: this.config.sip_wss      // URI to wss:// for SIPoverWS
                    },
                    sessionDescriptionHandlerFactoryOptions: {
                        constraints: {
                            audio: {
                              optional: [
                                  // Disable auto gain and other
                                  // autotune functions in Chrome.
                                  {googAutoGainControl: false},
                                  {googAutoGainControl2: false},
                                  {googEchoCancellation: false},
                                  {googEchoCancellation2: false},
                                  {googNoiseSuppression: false},
                                  {googNoiseSuppression2: false},
                                  {googHighpassFilter: false},
                                  {googAudioMirroring: false}
                              ]
                            },
                            video: false
                        },
                        peerConnectionOptions: {
                            iceCheckingTimeout: 500
                        }
                    },
                    password: this.config.sip_password    // SIP password
                    //stunServers: [],                     // Shutdown ICE
                    //iceCheckingTimeout: 500              // Quick exit ICE
                };

                this._initConnections();
            } else {
                // We reached our target server, but it returned an error.
                this.emit('error_init', {status: request.status, text: request.statusText});
            }
        };

        request.onerror = () => {
            this.emit('error_init', {status: request.status, text: request.statusText});
        };

        request.send();
    }

    terminate() {
        if (this._ws) {
            this.sc_stop_session();
            this._ws.close();
        } else {
            throw 'There is no active call.';
        };
    }

    initializeSIP() {
        if (this._ua !== undefined) {
            // SIP already started
            return
        };

        this._ua = new SIP.UA(this._uaConfig);
        this._ua.on('registered', () => {
            if (this._session === undefined) {
                // Now registered, invite the speechcloud
                 this._inviteSIP()
            }

            this.emit('_sip_registered');

            if (!!this._ws && this._ws.readyState !== this._ws.CLOSED) {
                // This is a normal state, the FreeSwitch or sip.js just
                // decided to re-register on the SIP level, so do nothing
                return;
            }

        });
    }

    _inviteSIP() {
        if (this._session !== undefined) {
            // Session already started
            return
        };
        this._session = this._ua.invite('speechcloud', {});

        this._session.on('failed', () => this.emit('error_sip_invite'));

        this._session.on('bye', () => {
            this.emit('_sip_closed');
            // websocket.onclose = function () {}; // disable onclose handler first
            if (this._ws) {
                this._ws.close();
            }
        });

        if ('tts' in this.options && this.options.tts) {
            let audio;
            // Create <audio/> or find already existed in HTML.
            if (typeof(this.options.tts) === 'boolean') {
                audio = document.createElement('audio');
                document.body.appendChild(audio);
            } else {
                audio = document.querySelector('audio' + this.options.tts);
            }

            this._session.on('trackAdded', function() {
              // We need to check the peer connection to determine which track was added

              var pc = this.sessionDescriptionHandler.peerConnection;

              // Gets remote tracks
              var remoteStream = new MediaStream();
              pc.getReceivers().forEach(function(receiver) {
                remoteStream.addTrack(receiver.track);
              });
              audio.srcObject = remoteStream;
              const playPromise = audio.play();
              if (playPromise !== null){
                  playPromise.catch(() => { audio.play(); })
              }
            });
        };
    }

    _initConnections() {
        this.emit('_sip_initializing', this.config.client_id);
        // Create User Agent

        this._ws = new WebSocket(this.config.client_wss);
        this._ws.onopen = () => {
            if (!this.initialize_sip_later) {
                this.initializeSIP()
            };

            this.emit('_ws_connected');
            this.emit('ws_connected');
        }
        this._ws.onerror = (err) => {
            this.emit('error_init', {status: err.code, text: err.reason});
        };
        this._ws.onmessage = (msg) => this._onMessage(msg);
        this._ws.onclose = () => {
            this.emit('_ws_closed');
            this.emit('ws_closed');
        }
    }

    _initAPIMethods(methods) {
        // Dynamically generate api methods based on data
        // received from a server.
        this._api_methods = methods;
        // Must be let because var and const leads to wrong result.
        for (let apiFn of this._api_methods) {
            this[apiFn] = (args={}) => {
                args.type = apiFn;
                this._ws.send(JSON.stringify(args));
            };
        }
    }

    _initAPIEvents(events) {
        //Dynamically set the list of api events based on data from server
        this._api_events = events;
    }

    _onMessage(msg) {
        const data = JSON.parse(msg.data);

        switch (data.type) {

            case 'asr_paused':
                this.isRecognizing = false;
                this.emit(data.type, data);
                break;

            case 'asr_recognizing':
                this.isRecognizing = true;
                this.emit(data.type, data);
                break;

            case 'sc_start_session':
                this._initAPIMethods(Object.keys(data.schema.methods));
                this._initAPIEvents(Object.keys(data.schema.events));
                this.emit('_ws_session', {type: 'started', id: data.session_id});
                if (!this.initialize_sip_later) {
                    // Create SIP call. If the server does not allocates an ASR
                    // engine then onclose is called and SIP call is terminated.

                    this._inviteSIP()
                }

                this.emit(data.type, data);
                break;

            default:
                this.emit(data.type, data);
        }
    }

}



